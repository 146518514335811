import React from "react";
// import { TimelineMax, TweenMax } from 'gsap';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import Button from '../components/Button'
import Popup from '../components/Popup'

import { setUserProps, setHeaderVisibility, setHeaderLoading } from '../actions/questions';
import ReadSpeaker from "../components/ReadSpeaker";

const PROVINCES = [
    'Noord-Holland', 
    'Zuid-Holland', 
    'Zeeland', 
    'Noord-Brabant', 
    'Utrecht', 
    'Flevoland', 
    'Friesland', 
    'Groningen', 
    'Drenthe', 
    'Overijssel', 
    'Gelderland', 
    'Limburg'
];
const EDUCATION = [
    'Geen diploma',
    'Basisonderwijs',
    'VMBO of MBO1',
    'HAVO/VWO of MBO24',
    'HBO of WO bachelor',
    'HBO/WO master',
    'Doctor'
];

class userFields extends React.Component {
    constructor(props) {
		super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            info: {
                gender: null,
                age: null,
                province: null,
                education: null
            },
			popup: {
                open: false,
                loading: false,
				content: {}
			}
        }
        
        this.onGenderChanged = this.onGenderChanged.bind(this);
        this.onAgeChanged = this.onAgeChanged.bind(this);
        this.onProvinceChanged = this.onProvinceChanged.bind(this);
        this.onEducationChanged = this.onEducationChanged.bind(this);
	}

    componentDidMount() {
        this.props.actions.setHeaderLoading(false);
        this.props.actions.setHeaderVisibility(true);
    }
    
    handleClick() {
        this.props.actions.setUserProps(this.state.info);
        this.props.actions.setHeaderLoading(true);
        this.props.history.push('/resultaat');
        
    }

    onGenderChanged(e) {
        this.setState({
            info: {
                ...this.state.info,
                gender: e.currentTarget.value
            }
        })
    }

    onProvinceChanged(e) {
        this.setState({
            info: {
                ...this.state.info,
                province: e.currentTarget.value
            }
        })
    }
    onAgeChanged(e) {
        this.setState({
            info: {
                ...this.state.info,
                age: e.currentTarget.value
            }
        })
    }
    onEducationChanged(e) {
        this.setState({
            info: {
                ...this.state.info,
                education: e.currentTarget.value
            }
        })
    }

    closePopup() {
		this.setState({
			popup: {
				open: false,
				content: {}
			}
		})
	}

	openPopup(content, e) {
		this.setState({
			popup: {
				open: true,
                content: {
                    title: 'Over jou',
                    body: 'Deze kieswijzer is onderdeel van een onderzoek naar de invloed van technologie op de toekomst van de democratie door Stichting Toekomstbeeld der Techniek (STT). Als kennisinstelling wil STT aandacht vragen voor de toenemende impact van technologie op onze samenleving en hoopt met het onderzoek een bijdragen te leveren aan een meer weloverwogen en menswaardige inzet van technologie. De uitkomst van deze kieswijzer leert ons enorm veel over hoe Nederlanders denken over technologie en democratie. Door deze kieswijzer in te vullen krijg je dus niet alleen een vrijblijvend stemadvies, je draagt ook daadwerkelijk bij aan onderzoek voor een betere toekomst. Je antwoorden worden geanonimiseerd geregistreerd en kunnen dus niet herleid worden tot jou als persoon. Indien je jezelf na het invullen van de Technologie Kieswijzer inschrijft voor onze nieuwsbrief staat dit los van het invullen van de Kieswijzer. Jouw gegevens worden niet aan elkaar gekoppeld. Alvast dank voor je bijdrage.'
                }
			}
		})
	}

    render() {
        const { popup } = this.state;
		const $showInfo = <span onClick={this.openPopup.bind(this)} className={`c-question__moreinfo`} />;
        const $popup = popup.open ? <Popup content={popup} close={this.closePopup.bind(this)} /> : false;
        const text = `Over jou. Deze informatie is waardevol voor ons onderzoek en wordt volledig anoniem opgeslagen.`;
        const $readSpeaker = <ReadSpeaker text={text} lang={'nl_nl'} voice={'Ilse'} speed={95} apikey={process.env.REACT_APP_READ_SPEAKER_KEY} />;
        return (
            <div className="page">
                {$readSpeaker}
                <div className="container c-about">
                    <div className="c-question__head" ref={(title) => this.title = title}>
                        <div className={`c-question__head__inner`}>
                            <h1 className={`c-main-title c-main-title--alt`}>Over jou</h1>
                            {$showInfo}
                        </div>
                    </div>
                    <div className="c-about__intro" ref={(intro) => this.intro = intro}>
                        <p>Deze informatie is waardevol voor ons onderzoek en wordt volledig anoniem opgeslagen.</p>
                    </div>
                    <div className="c-about__form" ref={(form) => this.form = form}>
                        <div className="c-about__controls">
                            <h2 className="parts">Geslacht</h2>
                            <div className="parts">
                                <div className="c-radio-group">
                                    <label><input type="radio" value="male" onChange={this.onGenderChanged} name="gender"/><span className="faux-radio"></span>Man</label>
                                    <label><input type="radio" value="female" onChange={this.onGenderChanged} name="gender"/><span className="faux-radio"></span>Vrouw</label>
                                    <label><input type="radio" value="other" onChange={this.onGenderChanged} name="gender"/><span className="faux-radio"></span>Anders</label>
                                </div>
                            </div>
                        </div>
                        <div className="c-about__controls">
                            <h2 className="parts">Leeftijd</h2>
                            <div className="parts">
                                <select onChange={this.onAgeChanged}>
                                    <option value="">Maak een keuze</option>
                                    <option value="Jonger dan 18 jaar">Jonger dan 18 jaar</option>
                                    <option value="18 tm 25 jaar">18 tm 25 jaar</option>
                                    <option value="26 tm 35 jaar">26 tm 35 jaar</option>
                                    <option value="36 tm 49 jaar">36 tm 49 jaar</option>
                                    <option value="50 tm 67 jaar">50 tm 67 jaar</option>
                                    <option value="68 tm 75 jaar">68 tm 75 jaar</option>
                                    <option value="Ouder dan 75 jaar">Ouder dan 75 jaar</option>
                                </select>
                            </div>
                        </div>
                        <div className="c-about__controls">
                            <h2 className="parts">Opleidingsniveau</h2>
                            <div className="parts">
                                <select onChange={this.onEducationChanged}>
                                    <option value="">Maak een keuze</option>
                                    {
                                        EDUCATION.map(p => {
                                            return (
                                                <option key={p} value={p}>{p}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="c-about__controls">
                            <h2 className="parts">Provincie</h2>
                            <div className="parts">
                                <select onChange={this.onProvinceChanged}>
                                    <option value="">Maak een keuze</option>

                                    {
                                        PROVINCES.map(p => {
                                            return (
                                                <option key={p} value={p}>{p}</option>
                                            )
                                        })
                                    }
                                    
                                </select>
                            </div>
                        </div>
                        <div className="c-btn-holder" ref={(pag) => this.pagination = pag }>
                            <div className={`c-pagination`}>
                                <Button subClass={`c-btn--arrow`} title={`Volgende`} onClick={this.handleClick} />
                            </div>
                        </div>
                    </div>
                    {$popup}
                </div>
            </div>
            )
        
    }
}

function mapStateToProps(state) {
    return {
      quiz: state.quiz,
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators({ setUserProps, setHeaderVisibility, setHeaderLoading }, dispatch)
    }
  }
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(userFields))