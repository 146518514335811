import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'; 
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { resetTest } from '../actions/questions'; 

class BackButton extends Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        const {province, question } = this.props.match.params;

        // if(!province && !question) {
        //     this.props.actions.resetTest()
        // }

        if(province && !question) {
            this.props.history.push('/');
        } else {
            this.props.history.goBack();
        }
        return;
    }

    render() {
        return <button className="c-btn c-btn--back" onClick={this.handleClick}><span>Terug</span></button>
    }
}

function mapStateToProps(state) {
    return {
      router: state.router
    }
}
  
function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators({ resetTest }, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BackButton));
