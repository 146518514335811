import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { setHeaderVisibility } from '../actions/questions';
import Loader from '../components/Loader';
import ReadSpeaker from "../components/ReadSpeaker";

class Page extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
        this.handleClick = this.handleClick.bind(this);
    }
    componentDidMount() {

        var isIE = '-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style;
        if(isIE) {
            document.body.classList.add('old-browser')
        }

        this.props.actions.setHeaderVisibility(false);

    }

    handleClick() {
        this.setState({
            isLoading: true,
        })
    }
    
    loadingComplete() {
        this.setState({
            isLoading: false,
        }, () => {
            const { province, settings } = this.props.quiz;
            if(settings.hasOwnProperty('has_province_page') && !settings.has_province_page) {
                this.props.history.push(`/kiezen/${province.slug}/${province.options[0].slug}`)
            } else {
                this.props.history.push('/kiezen')
            }
        })
    }

    render() {
        const introTitle = 'Technologie Kieswijzer.';
        const introBody = ' Er wordt veel geschreven over fake news, online privacy en de macht van bedrijven als Facebook en Google. ' +
            'Maar hoe gaan politieke partijen hier eigenlijk mee om? Vul de Technologie Kieswijzer van 2023 in en kom erachter.';
        const $readSpeaker = <ReadSpeaker text={introTitle + ' '+introBody} lang={'nl_nl'} voice={'Ilse'} speed={95} apikey={process.env.REACT_APP_READ_SPEAKER_KEY} />;
        return (
            <div className="pagewrap">
                <div className="container">
                    <div className="page">
                        <div className="c-home">
                            <div>
                                <h1 className="c-main-title c-home__title" ref={(title) => this.title = title}>
                                <span className="c-home__title__loader" ref={(loader) => this.loader = loader}>
                                    <Loader loop={1} isLoading={this.state.isLoading} onLoadingComplete={this.loadingComplete.bind(this)} />
                                </span>
                                Technologie<br/>Kieswijzer
                                </h1>
                                <div className="c-home__intro" ref={(intro) => this.intro = intro}>
                                    <p>
                                        {introBody}
                                    </p>
                                </div>
                            </div>
                            <div className="c-home__loader" ref={(loader) => this.loader = loader}>
                                <Loader loop={1} isLoading={this.state.isLoading} onLoadingComplete={this.loadingComplete.bind(this)} />
                            </div>
                        </div>
                        <div className="c-btn-holder c-btn-holder--home">
                            <button className={`c-btn c-btn-home ${this.state.isLoading ? 'c-btn-home--loading' : ''}`} onClick={this.handleClick} ref={(button) => this.button = button}>
                                <span>Start</span>
                               
                                <span className="c-btn-home-arrow">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"/></svg>
                                </span>
                            </button>

                        </div>
                        {$readSpeaker}
                    </div>

                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
      index: state.index,
      quiz: state.quiz
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators({ setHeaderVisibility  }, dispatch)
    }
  }
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Page))