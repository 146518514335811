import React from "react";
import anime from 'animejs';

const DURATION = 500;
const STAGGER = 45;


export default class Loader extends React.Component {

    componentDidMount() {

      
        // let duration = 1250;
        // let numberOfEls = 18;
        // let delay = duration / numberOfEls;
        // let iteration = 0;
        // this.tl = anime.timeline({
        //     duration: delay,
        //     autoplay: false,
        //     complete: () => { 
        //         iteration++;

        //         if(iteration === 1) {
        //             this.props.onLoadingComplete();
        //         } else {
        //             this.tl.restart(); 
        //         }

        //     }
        // });

        // [...this.el.querySelectorAll('path')].forEach(r => {
        //     this.tl.add({
        //         begin: () => {
        //             anime({
        //                 targets: r,
        //                 opacity: [.1, 1],
        //                 easing: 'easeInOutSine',
        //                 direction: 'alternate',
        //                 duration: duration * .4,

        //             });
        //         }
        //     })
        // })

        this.tl = anime({
            targets: this.el.querySelectorAll('path'),
            opacity: [.1, 1, .1],
            duration: DURATION,
            delay: (e, i) => i * STAGGER,
            loop: this.props.loop || 2,
            easing: 'linear',
            complete: () => {
                this.props.onLoadingComplete();
            }
        });
        
        

        anime({
            targets: this.el,
            rotate: 360,
            easing: 'linear',
            duration: 25000,
            loop: true
        })

    }

    componentDidUpdate() {
        if(!this.props.isLoading) {
            this.tl.pause();
        } else {
            this.tl.play();
        }
    }

    render() {
        return (
            <div>
                <svg ref={(el) => { this.el = el }} id="loader" width="391" height="394" viewBox="0 0 391 394" xmlns="http://www.w3.org/2000/svg">
                    <g stroke="#FFF" strokeWidth="14" fill="none" fillRule="evenodd" strokeLinecap="round">
                        
                        <path opacity=".1" d="M140.845 228.554L29.644 292.733" />
                        <path opacity=".1" d="M133.465 208.285L7.01 230.575" />
                        <path opacity=".1" d="M133.465 186.715L7.01 164.426" />
                        <path opacity=".1" d="M140.844 166.446l-111.2-64.179" />
                        <path opacity=".1" d="M154.714 149.923L72.178 51.596" />
                        <path opacity=".1" d="M173.401 139.138L129.485 18.521" />

                        <path opacity=".1" d="M195 7.032v125.936" />
                        <path opacity=".1" d="M260.173 17.55l-43.917 120.615" />
                        <path opacity=".1" d="M317.791 50.803l-82.536 98.326" />
                        <path opacity=".1" d="M360.557 101.75l-111.201 64.178" />
                        <path opacity=".1" d="M383.313 164.247l-126.454 22.289" />
                        <path opacity=".1" d="M383.312 230.754l-126.454-22.289" />

                        <path opacity=".1" d="M360.557 293.25l-111.201-64.178" />
                        <path opacity=".1" d="M317.791 344.197l-82.537-98.326" />
                        <path opacity=".1" d="M260.173 377.451l-43.917-120.615" />
                        <path opacity=".1" d="M195 261.032v125.936" />
                        <path opacity=".1" d="M173.402 255.862L129.485 376.48" />
                        <path opacity=".1" d="M154.715 245.078l-82.537 98.327" />

                        

                        

                        


                    </g>
                </svg>
            </div>
        )
    }
}