import React from "react";
// import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import anime from 'animejs';
import {ReactComponent as Logo } from './Logo.svg';

const DURATION = 200;
const STAGGER = 25;

class Header extends React.Component {

    componentDidMount() {
        this.tl = anime.timeline({
            loop: 1,
            duration: DURATION,
            autoplay: false,
        }).add({
            targets: '#header-logo path',
            opacity: [0.1, 1],
            easing: 'linear',
            delay: anime.stagger(STAGGER)
        }).add({
            targets: '#header-logo path',
            opacity: [1, 0.1],
            easing: 'linear',
            delay: anime.stagger(STAGGER)
        });
    }

    componentDidUpdate() {
        if(this.props.quiz.loading) {
            this.tl.play();
        }
    }

    render() {
        return (
            <CSSTransition in={this.props.visible} timeout={500} classNames="header-status">
                <header className="animated-header">
                    <div className="container">
                        <a className="c-logo" href="/">
                            <Logo />
                            {/* <img src="/media/images/logo.svg" alt=""/> */}
                        </a>
                    </div>
                </header>
            </CSSTransition>
        )
    }
}

const mapStateToProps = state => {
    return {
        quiz: state.quiz
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({}, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);