import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom';
import STTLogo from './STTLogo';
import Newsletter from '../containers/newsletter';
import BackButton from '../components/BackButton';

const SHOW_NEWSLETTER_ON = [
    // '/',
    '/resultaat'
]
const NOT_SHOW_BACKBUTTON_ON = [
    '/'
]

class Footer extends Component {
    render() {
        const { pathname } = this.props.location;
        return (
            <div className="c-footer" id="footer">
                {!
                    NOT_SHOW_BACKBUTTON_ON.includes(pathname) && (
                        <div className="container">
                            <div className="c-btn-holder c-btn-holder--footer" ref={(button) => this.button = button}>
                                <BackButton />
                            </div>
                        </div>
                    )
                }
                <div className="container">
                    {
                        SHOW_NEWSLETTER_ON.includes(pathname) && (
                            <Newsletter />
                        )
                    }
                </div>
                
                <div className="container container--footer">
                    
                    <div className="c-footer__inner">
                        <div className="c-footer__col">
                            <p>Deze Kieswijzer is ontwikkeld i.s.m. <a href="https://hetnieuwekiezen.nl/" target="_blank" rel="noopener noreferrer">Het Nieuwe Kiezen</a> en is onderdeel van STT96: <a href="https://stt.nl/nl/toekomstverkenningen/toekomst-van-de-democratie" target="_blank" rel="noopener noreferrer">Toekomst van de democratie</a>.</p> 
                        </div>
                        <div className="c-footer__col">
                            <div className="meta">
                                <NavLink className="c-meta-link" to={`/veelgestelde-vragen`}>Veelgestelde vragen</NavLink>
                                <NavLink className="c-meta-link" to={`/privacy`}>Privacy</NavLink>
                            </div>
                            <div className="c-footer__credits">
                                <STTLogo />
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		)
    }
}

export default withRouter(Footer);