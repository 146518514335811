import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

class NieuweKiezenLogo extends Component {

    render() {

        const { router } = this.props;
        if(router.location.pathname === '/resultaat') {
            return (
                <a href="https://stt.nl/nl" target="_blank" rel="noopener noreferrer">
                    <img src="/media/images/concept.svg" alt=""></img>
                </a>    
            )
        } else {
            return (
                <a href="https://stt.nl/nl" target="_blank" rel="noopener noreferrer">
                    <img src="/media/images/concept.svg" alt=""></img>
                </a>    
            )
        }        
    }
}

function mapStateToProps(state) {
    return {
      router: state.router
    }
}
  
function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators({ }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NieuweKiezenLogo);