import axios from 'axios';

export function setHeaderVisibility(status = false) {
    return {
        type: 'SET_HEADER_VISIBILITY',
        payload: status
    }
}

export function setHeaderLoading(status = false) {
    return {
        type: 'SET_HEADER_LOADING',
        payload: status
    }
}

export function SIGN_UP_USER_FOR_NEWSLETTER(email) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_API_ENDPONT}/api/subscribe`, {
                'email': email,
            }, {
                headers: {'X-API-KEY': process.env.REACT_APP_API_KEY}
            }).then(r => {
                resolve(r.data)
            }).catch(r => {
                reject(r.response.data.message);
            })
        })

    }
}

export function resetTest() {
    return {
        type: 'RESET_TEST',
    }
}

export function fetchData() {
    return (dispatch) => {
        axios.get(`${process.env.REACT_APP_API_ENDPONT}/api`, {
            headers: {'X-API-KEY': process.env.REACT_APP_API_KEY}
        })
        .then((response) => {
            dispatch({
                type: 'FETCH_DATA',
                payload: response.data
            })


            //Set first province (no province actually) in API
            const SETTINGS = response.data.settings;
		    const hasProvincePage = SETTINGS.filter(r => r.setting === 'has_province_page')[0].value;
            if(!hasProvincePage) {
                dispatch({
                    type: 'SET_PROVINCE',
                    payload: response.data.questions[0],
                    forced: true
                })
            }
        })
    }
}

export function setProvince(province = '', forced = false) {
    return {
        type: 'SET_PROVINCE',
        payload: province,
        forced: forced
    }
}

export function setThemes(themes = []) {
    return {
        type: 'SET_THEMES',
        payload: themes
    }
}

export function answerQuestion(question, answer) {
    return {
        type: 'ANSWER_QUESTION',
        payload: {
            question, answer
        }
    }
}

export function finishTest() {
    return {
        type: 'FINISH_TEST',
    }
}

export function setUserProps(user) {
    return {
        type: 'SET_USER_PROPS',
        payload: user
    }
}