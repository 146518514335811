import React, { Component } from 'react'

export default class ReadSpeaker extends Component {


    constructor(props) {
        super(props);
        this.state = {
            play: false,
            isLoaded:false,
            url:`https://tts.readspeaker.com/a/speak?key=${this.props.apikey}&command=produce&text=${this.props.text}&lang=${this.props.lang}&voice=${this.props.voice}&speed=${this.props.speed}`
        };

    }
    componentDidMount() {

        if(this.state.play) {
            this.readSpeaker.addEventListener('ended', () => this.setState({play: false}));
        }
    }

    componentWillUnmount() {
        if(this.state.play) {
            this.readSpeaker.currentTime = 0;
            this.readSpeaker.pause();
            this.readSpeaker.removeEventListener('ended', () => this.setState({play: false}));
        }
    }

    togglePlay = () => {
        this.setState({ play: !this.state.play }, () => {
            if(!this.state.isLoaded) {
                this.readSpeaker =   new Audio(this.state.url);
                this.setState({isLoaded:true});
            }
            this.state.play ? this.readSpeaker.play() : this.readSpeaker.pause();
        });
    }
    render() {
        const { play } = this.state;
        return (
            <div className="readspeaker">
                <button onClick={this.togglePlay}>

                    {
                        play ? (
                            <svg width="148px" height="148px" viewBox="0 0 148 148">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g id="Group">
                                        <circle fill="#FFFFFF" cx="74" cy="74" r="74"></circle>
                                        <rect fill="#3C3CD0" x="55" y="47" width="12" height="54"></rect>
                                        <rect fill="#3C3CD0" x="81" y="47" width="12" height="54"></rect>
                                    </g>
                                </g>
                            </svg>
                        ) : (
                            <svg width="148" height="148" viewBox="0 0 148 148">
                                <g fillRule="nonzero" fill="none">
                                    <circle fill="#FFF" cx="74" cy="74" r="74" />
                                    <g class="rs-icon" fill="#393CDD">
                                        <path
                                            d="M65.251 79.099h-6.94c-4.934 0-7.311 2.352-7.311 7.05v4.703c0 4.697 2.377 7.049 7.23 7.049h7.022l9.497 9.401C79.497 112 81.874 112 86.623 112H89V65h-2.32c-4.806 0-7.183 0-11.931 4.698l-9.498 9.4zM98.992 112l-2.308-4.353.927-.65c5.667-4.398 9.282-11.056 9.282-18.503 0-7.44-3.615-14.093-9.282-18.491l-.927-.656L98.992 65l1.86 1.304C107.658 71.581 112 79.566 112 88.494c0 8.933-4.342 16.924-11.148 22.204L98.992 112zm-4.628-8.711l-2.358-4.435.212-.187c2.768-2.597 4.485-6.194 4.485-10.172 0-3.967-1.717-7.558-4.485-10.162L92 78.146l2.364-4.44c4.535 3.521 7.428 8.846 7.428 14.789 0 5.954-2.893 11.28-7.428 14.794z" />
                                        <path
                                            d="M44.248 106c-4.72 0-7.248-2.299-7.248-6.9V43.9c0-4.603 2.41-6.9 7.366-6.9H82.78C87.566 37 90 39.297 90 43.9V60h-4.82V43.9c-.024-2.324-.024-2.3-2.434-2.3h-38.48c-2.451 0-2.451 0-2.451 2.3v55.2c0 2.297 0 2.297 2.439 2.297h21.668V106H44.248zm2.404-32.206v-4.597h19.264v4.597H46.652zm-.029-9.195v-4.605h28.904V64.6H46.623zm.024-9.202v-4.603h33.718v4.603H46.647z" />
                                    </g>
                                </g>
                            </svg>
                        )
                    }


                    <span>{ play ? 'Pauzeren' : 'Lees voor' }</span>

                </button>
            </div>
        );
    }
}
