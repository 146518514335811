import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setHeaderVisibility } from '../actions/questions'

class Disclaimer extends Component {
    
    componentDidMount() {
        this.props.actions.setHeaderVisibility(true);
    }

    render() {
        return (
            <div className="page page-privacy">
                <div className="container">
                    <div className={`o-content o-content--textpage`}>
                        <h1 className="c-main-title">Disclaimer en privacy statement</h1>

                        <p>De Technologie Kieswijzer is ontwikkeld door Stichting Toekomstbeeld der Techniek (hierna: STT).
                            STT dient het algemeen belang en heeft geen winstoogmerk. STT staat ingeschreven bij de Kamer van
                            Koophandel onder nummer 27198926, SBI-code 94997, en is bij de Belastingdienst geregistreerd als
                            een Algemeen Nut Beogende Instelling (ANBI, RSIN 003014034).</p>

                        <p>De voorwaarden van deze disclaimer en dit privacy statement zijn van toepassing op
                            www.technologiekieswijzer.nl. Door deze website te bezoeken en/of de op of via deze website
                            aangeboden informatie te gebruiken, verklaart u zich akkoord met de toepasselijkheid van deze
                            disclaimer en dit privacy statement.</p>

                        <h2>Disclaimer</h2>
                        <h3>Aansprakelijkheid</h3>
                        <p>STT zal er alles aan doen om andermans auteursrechten te respecteren en na te leven. Mocht
                            onverhoopt toch gebruik zijn gemaakt van andere bronnen (zonder bronvermelding) neem dan
                            contact op met de initiatiefnemer (vanbelkom@stt.nl). De door STT verstrekte informatie is ontleend
                            aan bronnen die betrouwbaar mogen worden geacht, maar voor de juistheid en volledigheid daarvan
                            kan niet worden ingestaan.</p>
                        <h3>Gebruik van de website</h3>
                        <p>Aan de verstrekte informatie kunnen geen rechten worden ontleend. STT aanvaardt geen enkele
                            aansprakelijkheid voor de inhoud van sites die niet door STT worden onderhouden en waarnaar
                            wordt verwezen of die verwijzen naar de site van STT. Hoewel STT alles in het werk stelt om misbruik
                            te voorkomen, is STT niet aansprakelijk voor informatie en/of berichten die door gebruikers van de
                            website via het internet verzonden worden.</p>





                        <h2>Privacy statement</h2>


                        <p>Jouw privacy is voor STT van groot belang. Wij houden ons dan ook aan de relevante wet- en
                            regelgeving over privacy, waaronder de Algemene Verordening Gegevensbescherming.<br /><br />Dit betekent
                            dat wij:</p>
                        <ul>
                            <li>Onze doeleinden duidelijk vastleggen voordat wij jouw persoonlijke gegevens verwerken, via
                                deze privacyverklaring;</li>
                            <li>Zo min mogelijk persoonlijke gegevens opslaan en enkel de gegevens opslaan die nodig zijn
                                voor onze doeleinden;</li>
                            <li>Expliciet toestemming vragen voor de verwerking van jouw persoonlijke gegevens, mocht
                                toestemming verplicht zijn;</li>
                            <li>Benodigde beveiligingsmaatregelen treffen om jouw persoonlijke gegevens te beschermen.
                                Wij leggen deze verplichtingen ook op aan partijen die persoonsgegevens voor ons
                                verwerken;</li>
                            <li>Jouw rechten respecteren, zoals het recht op inzage, correctie of verwijdering van jouw bij
                                ons verwerkte persoonsgegevens.</li>
                        </ul>

                        <p>Jouw gegevens zijn veilig bij ons en wij zullen deze gegevens altijd netjes gebruiken. In deze
                            privacyverklaring leggen we uit wat we bij STT doen met informatie over jou. Als je hier vragen over
                            hebt neem dan contact op met STT via <a href="mailto:info@stt.nl">info@stt.nl</a>.
                        </p>




                        <h3>Welke gegevens worden er verzameld?</h3>
                        <p>Wanneer je de Technologie Kieswijzer start, wordt deze lokaal geladen op jouw eigen apparaat. Je
                            antwoorden op de onderwerpen en de uitslag na het voltooien van de Technologie Kieswijzer
                            worden geanonimiseerd geregistreerd. Dit geldt ook voor de ingevulde keuzes bij de ‘Over jou’
                            pagina, waarbij gevraagd wordt naar je geslacht, leeftijd en provincie. Antwoorden kunnen dus niet
                            herleid worden tot een persoon. Dankzij de verzameling geanonimiseerde gegevens krijgen we een
                            beeld van welke keuzes gebruikers van de stemhulp maken. Deze informatie kan dienen als basis
                            voor onderzoeksdoeleinden en publicaties.</p>

                        <h3>Cookies en Google Analytics</h3>
                        <p>Wij maken geen gebruik van cookies of Google Analytics.</p>

                        <h3>Verzenden van nieuwsbrieven</h3>
                        <p>Indien je jezelf na het invullen van de Technologie Kieswijzer inschrijft voor onze nieuwsbrief staat dit
                            los van het invullen van de Kieswijzer. Jouw gegevens worden niet aan elkaar gekoppeld. Je wordt
                            alleen met jouw toestemming toegevoegd aan de lijst van abonnees. In de nieuwsbrief word je op de
                            hoogte gehouden van de onderzoeksresultaten. Hiervoor gebruiken wij je e-mailadres. Wij bewaren
                            deze informatie tot drie maanden nadat je het abonnement hebt opgezegd. Het abonnement op een
                            nieuwsbrief kun je op ieder moment opzeggen. Iedere nieuwsbrief bevat een afmeldlink.</p>

                        <h3>Verstrekking aan andere bedrijven of instellingen</h3>
                        <p>Met uitzondering van de partijen die nodig zijn om de hierboven genoemde diensten te leveren,
                            geven wij jouw persoonsgegevens onder geen voorwaarde aan andere bedrijven of instellingen,
                            behalve als wij dat wettelijk verplicht zijn (bijvoorbeeld als de politie dat eist bij een vermoeden van
                            een misdrijf).</p>

                        <h3>Beveiliging</h3>
                        <p>Beveiliging van persoonsgegevens is voor ons van groot belang. Wij zorgen dat jouw gegevens bij ons
                            goed beveiligd zijn. We passen de beveiliging steeds aan en letten goed op wat er mis kan gaan.</p>


                        <h3>Jouw rechten</h3>
                        <p>Als je vragen hebt of wilt weten welke persoonsgegevens wij van jou hebben bewaard, kun je altijd
                            contact met ons opnemen. Zie de contactgegevens hieronder.<br /><br />
                            Je hebt de volgende rechten:</p>

                        <ul>
                            <li>uitleg krijgen over welke persoonsgegevens we hebben en wat we daarmee doen</li>
                            <li>inzage in de precieze persoonsgegevens die we hebben</li>
                            <li>het laten corrigeren van fouten</li>
                            <li>het laten verwijderen van verouderde persoonsgegevens</li>
                            <li>intrekken van toestemming</li>
                            <li>een bepaalde verwerking beperken</li>
                            <li>bezwaar maken tegen een bepaald gebruik</li>
                        </ul>

                        <p>We zullen je vragen duidelijk aan te geven wie je bent, zodat we er zeker van kunnen zijn dat we
                            jouw gegevens aanpassen of verwijderen, en niet die van een ander.</p>
                        <p>Wij zullen in principe binnen 30 dagen aan jouw verzoek voldoen. Deze termijn kan echter worden
                            verlengd om redenen die verband houden met de specifieke rechten van betrokkenen of de complexiteit van
                            het verzoek. Als wij deze termijn verlengen, zullen wij je daarvan tijdig op de hoogte
                            stellen.</p>

                        <h3>Klacht indienen</h3>
                        <p>Als je een klacht in wil dienen over het gebruik van jouw persoonsgegevens kan je een bericht sturen
                            naar STT. Wij pakken een klacht intern op, en communiceren hierover met jou. Als je vindt dat wij je
                            niet op de juiste manier helpen, dan heb je het recht om een klacht in te dienen bij de
                            toezichthouder: de Autoriteit Persoonsgegevens.</p>

                        <h3>Contactgegevens</h3>
                        <p>
                            Stichting Toekomstbeeld der Techniek<br />
                            Koninginnegracht 19<br />
                            2514 AB ’s-Gravenhage<br />
                            E-mailadres: <a href="mailto:info@stt.nl">info@stt.nl</a><br />
                            Telefoon: <a href="tel:+31703029830">070-3029830</a><br />
                        </p>

                    </div>

                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
      quiz: state.quiz
    }
}
  
function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators({ setHeaderVisibility }, dispatch)
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Disclaimer)