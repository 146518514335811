import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FaqItem from '../components/FaqItem';
import { setHeaderVisibility } from '../actions/questions'

class Faq extends Component {
    
    componentDidMount() {
        this.props.actions.setHeaderVisibility(true);
    }

    render() {
        return (
            <div className="page">
                <div className="container">
                    <div className={`o-content o-content--textpage`}>
                        <h1 className="c-main-title">
                            <span>Veelgestelde</span>
                            <span> vragen</span>
                        </h1>
                        <p>Deze kieswijzer is ontwikkeld voor de Tweede Kamerverkiezingen van 2023 die op 22 november worden gehouden.</p>

                        
                        <div className={`c-faq`}>
                            <FaqItem question={`Door wie is de kieswijzer ontwikkeld?`}>
                                <p>Deze kieswijzer is ontwikkeld door Stichting Toekomstbeeld der Techniek (STT). Als onafhankelijke kennisinstelling voert STT maatschappijgerichte toekomstverkenningen uit op het snijvlak van technologie en samenleving. Voor de totstandkoming van deze kieswijzer is samengewerkt met Het Nieuwe Kiezen. Zij ontwikkelde het innovatieve concept voor kieswijzers waarbij geen gebruik wordt gemaakt van stellingen (eens/oneens), maar van uiteenlopende oplossingsrichtingen per onderwerp. De techniek en vormgeving van deze kieswijzer zijn ontwikkeld door communicatiebureau Just.</p>
                            </FaqItem>

                            <FaqItem question={`Waarom ontbreken sommige politieke partijen? `}>
                                <p>Bij de ontwikkeling van deze kieswijzer hebben we geprobeerd zoveel mogelijk politieke partijen te betrekken. Zowel de zittende partijen, als nieuwe en opkomende partijen. Wij hebben ons hierbij onder andere laten informeren door de actuele peilingen. Het is mogelijk dat er partijen ontbreken in deze kieswijzer. Dit komt doordat niet alle partijen hebben gereageerd op onze oproep om mee te werken (zoals de PVV en BVNL) of uit eigen overwegingen hebben besloten om niet deel te nemen (zoals Nieuw Sociaal Contract en DENK).</p>
                            </FaqItem>
                            
                            <FaqItem question={`Waarom een speciale Technologie Kieswijzer?`}>
                                <p>Ondanks het feit dat er in toenemende mate wordt bericht over de kwetsbaarheid van onze online privacy, de verspreiding van fake news en de groeiende macht van Techbedrijven zoals Meta en Google, wordt er tijdens verkiezingscampagnes in verhouding zeer weinig aandacht besteed aan deze onderwerpen. In de StemWijzer van 2021 ging van de 30 stellingen niet één over digitale technologie. Hoe willen politieke partijen fake news bestrijden en online privacy bevorderen? Om dit in kaart te brengen ontwikkelde STT deze speciale Technologie Kieswijzer. </p>
                            </FaqItem>
                            
                            <FaqItem question={`Waarom maakt deze kieswijzer onderdeel uit van onderzoek?`}>
                                <p>Het doel van deze Kieswijzer is drieledig: naast dat het burgers moet helpen om zich beter te kunnen oriënteren op tech-gerelateerde onderwerpen en het politieke partijen moet aanzetten om zich hierover concreter uit te spreken, is deze kieswijzer een onderzoekstool. De uitkomst leert ons namelijk enorm veel over hoe Nederlanders denken over de toekomst van de digitale samenleving. Als kennisinstelling wil STT aandacht vragen voor de invloed van de toenemende digitalisering van onze samenleving, maar neemt hierin een neutrale positie in. Als onafhankelijke stichting hebben wij een ANBI keurmerk en daarmee geen inhoudelijke belangen.</p>
                            </FaqItem>
                            
                            <FaqItem question={`Wat maakt deze kieswijzer anders dan traditionele stemhulpen?`}>
                                <p>Een traditionele stemhulp bestaat uit een reeks stellingen waarmee je het eens of oneens bent. Dit levert echter een te zwart-wit beeld op. In deze vernieuwde stemhulp kies je bij ieder thema uit 5 uiteenlopende oplossingen voor een probleem. Op deze wijze krijg je een genuanceerder beeld van welke partij het beste bij jou past en stem je nóg wijzer. Daarbij krijg je bij de uitslag niet alleen te zien welke partij in het algemeen het best bij je past, maar kun je ook per thema zien hoe de verschillende partijen zich tot elkaar verhouden.</p>
                            </FaqItem>
                            
                            <FaqItem question={`Waarom kan ik maar voor één van de vijf antwoordopties kiezen?`}>
                                <p>Het concept is zo ingericht dat je steeds 1 antwoordoptie moet aanvinken. De vraag is namelijk wat de béste oplossing is. Oftewel waar de prioriteit moet liggen. Dit sluit andere oplossingen dus niet volledig uit, maar geeft wel aan waar de (politieke) focus moet liggen. Het doel daarbij is om te laten zien dat er nuances zijn in de praktijk. Binaire stellingen doen namelijk geen recht aan de complexiteit van de realiteit. De vraag is dus niet óf we moeten investeren in online privacy, maar hoé we dat moeten doen.</p>
                            </FaqItem>

                            <FaqItem question={`Waarom worden sommige opties door geen enkele partij gerepresenteerd?`}>
                                <p>De inhoud van deze kieswijzer is onafhankelijk van politieke partijen samengesteld. Voor de inhoud is niet alleen gekeken naar partijprogramma’s, maar ook naar relevante en actuele onderzoeken, kamerbrieven en nieuwsartikelen. Pas nadat de inhoud volledig was samengesteld is deze voorgelegd aan politieke partijen. Aan hen is vervolgens gevraagd om aan te geven welke optie zij vertegenwoordigen en wat de toelichting hierop is. Het kan dus voorkomen dat je voor een antwoordoptie hebt gekozen die wel relevant en actueel is, maar niet door een politieke partij wordt vertegenwoordigd. Deze ‘vergeten’ oplossingsrichtingen zullen we na de verkiezingen aankaarten bij politieke partijen.</p>
                            </FaqItem>

                            
                        </div>
                    </div>
                    
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
      quiz: state.quiz
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators({ setHeaderVisibility }, dispatch)
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Faq)